import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/wrhous-cd';

class WrhousCd {
    /** 창고 리스트 조회 */
    async getWrhousList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/list` ,{params} );
        return data;
    }
    /** 창고 상세 조회 */
    async getWrhousDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/detail` ,{params} );
        return data;
    }
    /** 창고 권한유저 조회 */
    async getWrhousAuthList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/authList` ,{params} );
        return data;
    }
    /** 창고 등록 */
    async postWrhousDetail(params){
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/detail` ,params);
        return data;
    }
    /** 창고 수정 */
    async patchWrhousDetail(params){
        const {data} = await  GolfErpAPI.http.patch(`${ROOT_PATH}/detail` ,params);
        return data;
    }

}
export default new WrhousCd();
